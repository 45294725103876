import WOW from "wowjs";
import Grid from "@material-ui/core/Grid";
import { Button } from "reactstrap";
import { withStyles } from "@material-ui/core/styles";
import { ValidatorForm, TextValidator } from "react-material-ui-form-validator";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import VisibilitySensor from "react-visibility-sensor";

import "./index.css";
import "../../static/css/animate.css";
import "animate.css";
import Sidebar from "../../components/sidebar";
import NavbarInner from "../../components/navbar-inner";
import {
  toggleLoader,
  challengeDetails,
  getUserName
} from "../../store/actions/Auth";

const CustomTextField = withStyles({
  root: {
    "& .MuiInputBase-input": {
      color: "#fff", // Text color
    },
    "& .MuiInput-underline:before": {
      borderBottomColor: "#fff", // Semi-transparent underline
    },
    "& .MuiInput-underline:hover:before": {
      borderBottomColor: "#fff", // Solid underline on hover
    },
    "& .MuiInput-underline:after": {
      borderBottomColor: "#fa6634", // Solid underline on focus
    },
  },
  input: {
    "&:-webkit-autofill": {
      transitionDelay: "9999s",
      transitionProperty: "background-color, color",
    },
  },
})(TextValidator);

const ChallengeDetails = () => {
  const [activeSection, setActiveSection] = useState("");
  const [MaximumChips, setMaximumChips] = useState("");
  const [MinimumChips, setMinimumChips] = useState("");

  const dispatch = useDispatch();
  const {
    loading,
    isActive,
    chipsBalance,
    chipsBalanceLoading,
    chipsBalanceError,
  } = useSelector((state) => ({
    loading: state.Auth.loading,
    isActive: state.Sidebar.isActive,
    chipsBalance: state.Auth.chipsBalance,
    chipsBalanceLoading: state.Auth.chipsBalanceLoading,
    chipsBalanceError: state.Auth.chipsBalanceError,
  }));

  useEffect(() => {
    new WOW.WOW({ live: true }).init();
    dispatch(getUserName());
  }, [dispatch]);

  const handleFormChange = (event) => {
    const { name, value } = event.target;
    if (name === "MaximumChips") setMaximumChips(value);
    if (name === "MinimumChips") setMinimumChips(value);
  };

  const handleMultiplierClick = (multiplier) => {
    setMinimumChips((prev) => prev * multiplier);
    setMaximumChips((prev) => prev * multiplier);
  };

  const handleFormSubmit = async () => {
    dispatch(toggleLoader(true));
    await dispatch(
      challengeDetails({
        minChipsLimit: MinimumChips,
        maxChipsLimit: MaximumChips,
      })
    );
    dispatch(toggleLoader(false));
  };

  const setVisible = (active) => {
    setActiveSection((prevActiveSection) =>
      prevActiveSection === active ? "" : active
    );
  };

  let chipsBalanceContent;
  if (chipsBalanceLoading) {
    chipsBalanceContent = <p>Loading chips balance...</p>;
  } else if (chipsBalanceError) {
    chipsBalanceContent = (
      <p>Error loading chips balance: {chipsBalanceError}</p>
    );
  } else {
    chipsBalanceContent = chipsBalance;
  }

  return (
    <div
      className={
        isActive
          ? "challenge-details-page singup-page transaction-history-page active"
          : "challenge-details-page singup-page transaction-history-page"
      }
    >
      <Sidebar activeSection={activeSection} />
      <VisibilitySensor onChange={() => setVisible("ChallengeDetails")}>
        <div className="content">
          <NavbarInner />
          <div className="transaction-box signup-form challenge-page">
            <div className="challenge-top-area">
              <h2>Challenge Details</h2>
              {/* <p>{username}</p> */}
            </div>
            <ValidatorForm
              className="validatorForm"
              onSubmit={handleFormSubmit}
            >
              <div className="changes-limit-box">
                <h4>Set your challenge limit</h4>
                <Grid
                  container
                  spacing={2}
                  className="group-input"
                  alignItems="flex-end"
                >
                  <Grid className="signup-fields" item xs={6}>
                    <CustomTextField
                      fullWidth
                      className="MyTextField"
                      placeholder="000 Chips"
                      autoComplete="off"
                      label="Maximum"
                      name="MaximumChips"
                      type="text"
                      value={MaximumChips}
                      margin="dense"
                      onChange={handleFormChange}
                      validators={["required"]}
                      errorMessages={["Maximum can not be empty"]}
                    />
                    <span classNam="chipz-txt">CHIPZ</span>
                  </Grid>
                  <Grid className="signup-fields" item xs={6}>
                    <CustomTextField
                      fullWidth
                      className="MyTextField"
                      placeholder="000 Chips"
                     autoComplete="off"
                      label="Minimum"
                      name="MinimumChips"
                      type="text"
                      value={MinimumChips}
                      margin="dense"
                      onChange={handleFormChange}
                      validators={["required"]}
                      errorMessages={["Minimum can not be empty"]}
                    />
                    <span classNam="chipz-txt">CHIPZ</span>
                  </Grid>
                </Grid>
              </div>
              <div className="amount-box">
                <ul className="list-x">
                  <li>
                    <button
                      type="button"
                      onClick={() => handleMultiplierClick(5)}
                    >
                      5x
                    </button>
                  </li>
                  <li>
                    <button
                      type="button"
                      onClick={() => handleMultiplierClick(10)}
                    >
                      10x
                    </button>
                  </li>
                  <li>
                    <button
                      type="button"
                      onClick={() => handleMultiplierClick(20)}
                    >
                      20x
                    </button>
                  </li>
                  <li>
                    <button
                      type="button"
                      onClick={() => handleMultiplierClick(50)}
                    >
                      50x
                    </button>
                  </li>
                  <li>
                    <button
                      type="button"
                      onClick={() => handleMultiplierClick(100)}
                    >
                      100x
                    </button>
                  </li>
                  <li>
                    <button
                      type="button"
                      onClick={() => handleMultiplierClick(250)}
                    >
                      250x
                    </button>
                  </li>
                  <li>
                    <button type="button">other</button>
                  </li>
                </ul>
              </div>
              <div className="quantity-box">
                <h2>
                  Balance <span>Chipz {chipsBalanceContent}</span>
                </h2>
              </div>
              <Button type="submit" disabled={loading}>
                {!loading ? (
                  "Done"
                ) : (
                  <i className="fa fa-spinner fa-spin fa-1x fa-fw" />
                )}
              </Button>
            </ValidatorForm>
          </div>
        </div>
      </VisibilitySensor>
    </div>
  );
};

export default ChallengeDetails;