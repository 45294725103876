import WOW from "wowjs";
import Grid from "@material-ui/core/Grid";
import { Button } from "reactstrap";
import { withStyles } from "@material-ui/core/styles";
import { ValidatorForm, TextValidator } from "react-material-ui-form-validator";
import React, { useState, useEffect, useCallback } from "react";
import EventBus from "eventing-bus";
import { useDispatch, useSelector } from "react-redux";

import "./index.css";
import "../../static/css/animate.css";
import "animate.css";

import Sidebar from "../../components/sidebar";
import { toggleLoader, NewPaswordRequest } from "../../store/actions/Auth";
import { useLocation, useNavigate } from "react-router-dom";

const CustomTextField = withStyles({
  root: {
    "& .MuiInputBase-input": {
      color: "#fff", // Text color
    },
    "& .MuiInput-underline:before": {
      borderBottomColor: "#fff", // Semi-transparent underline
    },
    "& .MuiInput-underline:hover:before": {
      borderBottomColor: "#fff", // Solid underline on hover
    },
    "& .MuiInput-underline:after": {
      borderBottomColor: "#fa6634", // Solid underline on focus
    },
  },
  input: {
    "&:-webkit-autofill": {
      transitionDelay: "9999s",
      transitionProperty: "background-color, color",
    },
  },
})(TextValidator);

const NewPassword = () => {
  const [newPassword, setNewPassword] = useState("");
  const [confirmNewPassword, setConfirmNewPassword] = useState("");

  const loading = useSelector((state) => state.Auth.loading);
  const dispatch = useDispatch();
  const location = useLocation();
  const navigate = useNavigate();

  const email = location.state?.email;

  useEffect(() => {
    new WOW.WOW({
      live: true,
    }).init();
  }, []);

  const handleFormChange = (event) => {
    const { name, value } = event.target;
    if (name === "newPassword") {
      setNewPassword(value);
    } else if (name === "confirmNewPassword") {
      setConfirmNewPassword(value);
    }
  };

  const handleFormSubmit = useCallback(async () => {
    dispatch(toggleLoader(true));

    if (newPassword !== confirmNewPassword) {
      EventBus.publish("error", "Password and ConfirmPassword do not match");
      dispatch(toggleLoader(false));
      return;
    }

    await dispatch(
      NewPaswordRequest({ data: { email, newPassword }, history: navigate })
    );

    dispatch(toggleLoader(false));
  }, [newPassword, confirmNewPassword, dispatch, email, navigate]);

  return (
    <div className="singup-page">
      <Sidebar />
      <div className="content">
        <div className="signup-form">
          <h2>New Password</h2>
          <ValidatorForm className="validatorForm" onSubmit={handleFormSubmit}>
            <Grid
              container
              spacing={1}
              className="group-input"
              alignItems="flex-end"
            >
              <Grid className="signup-fields" item xs={12}>
                <CustomTextField
                  fullWidth
                  className="MyTextField"
                  placeholder="Enter New Password"
                  autoComplete="off"
                  name="newPassword"
                  type="password"
                  value={newPassword}
                  margin="dense"
                  onChange={handleFormChange}
                  validators={["required"]}
                  errorMessages={["Email address can not be empty"]}
                />
              </Grid>
              <Grid className="signup-fields" item xs={12}>
                <CustomTextField
                  fullWidth
                  className="MyTextField"
                  placeholder="Confirm New Password"
                  autoComplete="off"
                  name="confirmNewPassword"
                  type="password"
                  value={confirmNewPassword}
                  margin="dense"
                  onChange={handleFormChange}
                  validators={["required"]}
                  errorMessages={["Password can not be empty"]}
                />
              </Grid>
            </Grid>
            <Button type="submit" disabled={loading}>
              {!loading ? (
                "Set New Password"
              ) : (
                <i className="fa fa-spinner fa-spin fa-1x fa-fw" />
              )}
            </Button>
          </ValidatorForm>
        </div>
      </div>
    </div>
  );
};

export default NewPassword;
