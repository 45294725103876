import WOW from "wowjs";
import Grid from "@material-ui/core/Grid";
import { Button } from "reactstrap";
import { withStyles } from "@material-ui/core/styles";
import { ValidatorForm, TextValidator } from "react-material-ui-form-validator";
import React, { useState, useEffect } from "react";
import { Link, useNavigate } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";

import "./index.css";
import "../../static/css/animate.css";
import "animate.css";

import { toggleLoader, signIn } from "../../store/actions/Auth";

const CustomTextField = withStyles({
  root: {
    "& .MuiInputBase-input": {
      color: "#fff", // Text color
    },
    "& .MuiInput-underline:before": {
      borderBottomColor: "#fff", // Semi-transparent underline
    },
    "& .MuiInput-underline:hover:before": {
      borderBottomColor: "#fff", // Solid underline on hover
    },
    "& .MuiInput-underline:after": {
      borderBottomColor: "#fa6634", // Solid underline on focus
    },
  },
  input: {
    "&:-webkit-autofill": {
      transitionDelay: "9999s",
      transitionProperty: "background-color, color",
    },
  },
})(TextValidator);

const SignIn = () => {
  const [formData, setFormData] = useState({
    email: "",
    password: "",
  });

  const dispatch = useDispatch();
  const loading = useSelector((state) => state.Auth.loading);
  const navigate = useNavigate();

  useEffect(() => {
    new WOW.WOW({ live: true }).init();
  }, []);

  const handleFormChange = (event) => {
    const { name, value } = event.target;
    setFormData((prevState) => ({
      ...prevState,
      [name]: value,
    }));
  };

  const handleFormSubmit = async () => {
    const { email, password } = formData;

    dispatch(toggleLoader(true));

    const data = { email, password };

    await dispatch(signIn({ data, history: navigate }));

    dispatch(toggleLoader(false));
  };

  return (
    <div className="singup-page singup-without-sidebar">
      <div className="content">
        <div className="signup-form">
          <h2>Sign in</h2>
          <ValidatorForm className="validatorForm" onSubmit={handleFormSubmit}>
            <Grid
              container
              spacing={1}
              className="group-input"
              alignItems="flex-end"
            >
              <Grid className="signup-fields" item xs={12}>
                <CustomTextField
                  fullWidth
                  className="MyTextField"
                  placeholder="Email address"
                  autoComplete="off"
                  name="email"
                  type="email"
                  value={formData.email}
                  margin="dense"
                  onChange={handleFormChange}
                  validators={["required"]}
                  errorMessages={["Email address can not be empty"]}
                />
              </Grid>
              <Grid className="signup-fields" item xs={12}>
                <CustomTextField
                  fullWidth
                  className="MyTextField"
                  placeholder="Password"
                  autoComplete="off"
                  name="password"
                  type="password"
                  value={formData.password}
                  margin="dense"
                  onChange={handleFormChange}
                  validators={["required"]}
                  errorMessages={["Password can not be empty"]}
                />
              </Grid>
            </Grid>
            <Button type="submit" disabled={loading}>
              {!loading ? (
                "Log In"
              ) : (
                <i className="fa fa-spinner fa-spin fa-1x fa-fw" />
              )}
            </Button>
          </ValidatorForm>
          <p className="forgetpass">
            <Link className="singin-btn" to="/ForgotPassword">
              Forgot password?
            </Link>
          </p>
        </div>
      </div>
    </div>
  );
};

export default SignIn;
