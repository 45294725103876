import { useDispatch, useSelector } from "react-redux";
import React, { useEffect } from "react";
import { signOut, getUserName } from "../../store/actions/Auth";

import "./index.css";

const NavbarInner = () => {
  const dispatch = useDispatch();
  const username = useSelector(state => state.Auth.username);

  useEffect(() => {
    dispatch(getUserName());
  }, [dispatch]);

  return (
    <div className="chips-exchange-nav nav-two">
      <nav className="navbar sidenav" id="sidenav-1" data-mdb-hidden="false">
        <div className="auto-container">
          <div className="inner-container">
            <div className="row">
              <div className="nav-links" id="navbarSupportedContent">
                <div className="searchbox">
                  <div class="dropdown">
                    <button
                      class="btn btn-secondary dropdown-toggle"
                      type="button"
                      id="dropdownMenuButton"
                      data-toggle="dropdown"
                      aria-haspopup="true"
                      aria-expanded="false"
                    >
                      <img
                        src={require("../../static/images/user.png")}
                        alt=""
                      />

                      <p>{username}</p>
                    </button>
                    <div
                      class="dropdown-menu"
                      aria-labelledby="dropdownMenuButton"
                    >
                      <a
                        class="dropdown-item"
                        href="/"
                        onClick={() => dispatch(signOut())}
                      >
                        signOut
                      </a>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </nav>
    </div>
  );
};

export default NavbarInner;
