import WOW from "wowjs";
import { Grid, withStyles, FormControlLabel, RadioGroup, Radio, InputLabel } from "@material-ui/core";
import { Button } from "reactstrap";
import { ValidatorForm, TextValidator } from "react-material-ui-form-validator";
import React, { useState, useEffect } from "react";
import Tab from "react-bootstrap/Tab";
import Tabs from "react-bootstrap/Tabs";
import { useDispatch, useSelector } from "react-redux";
import { toast } from "react-toastify";
import VisibilitySensor from "react-visibility-sensor";

import "./index.css";
import "../../static/css/animate.css";
import "animate.css";

import Sidebar from "../../components/sidebar";
import NavbarInner from "../../components/navbar-inner";
import {
  fetchChipsBalanceRequest,
  checkoutByPayPal,
  purchaseChipsByPayPal,
  refundChipsByPayPal,
  fetchTransactionRequest
} from "../../store/actions/Auth";
import { PayPalScriptProvider, PayPalButtons } from "@paypal/react-paypal-js";
import { useNavigate } from "react-router-dom"

const CustomTextField = withStyles({
  root: {
    "& .MuiInputBase-input": {
      color: "#fff", // Text color
    },
    "& .MuiInput-underline:before": {
      borderBottomColor: "#fff", // Semi-transparent underline
    },
    "& .MuiInput-underline:hover:before": {
      borderBottomColor: "#fff", // Solid underline on hover
    },
    "& .MuiInput-underline:after": {
      borderBottomColor: "#fa6634", // Solid underline on focus
    },
  },
  input: {
    "&:-webkit-autofill": {
      transitionDelay: "9999s",
      transitionProperty: "background-color, color",
    },
  },
})(TextValidator);

const ChipzBalance = () => {
  const [activeSection, setActiveSection] = useState("");
  const [transactionMode, setTransactionMode] = useState("buy");
  const [amountType, setAmountType] = useState("");
  const [amount, setAmount] = useState("");
  const [chipz, setChipz] = useState(0);
  const [refundType, setRefundType] = useState("");
  const [email, setEmail] = useState("");
  const [isLoading, setIsLoading] = useState(false);

  const navigate = useNavigate();

  const dispatch = useDispatch();
  const { chipsBalance, chipsBalanceLoading, chipsBalanceError, isActive, paymentsLoading, paymentsError, payments } =
    useSelector((state) => ({
      chipsBalance: state.Auth.chipsBalance,
      payments: state.Auth.payments,
      chipsBalanceLoading: state.Auth.chipsBalanceLoading,
      chipsBalanceError: state.Auth.chipsBalanceError,
      isActive: state.Sidebar.isActive,
      paymentsLoading: state.Auth.paymentsLoading,
      paymentsError: state.Auth.paymentsError
    }));

  useEffect(() => {
    new WOW.WOW({ live: true }).init();
    dispatch(fetchChipsBalanceRequest());
    dispatch(fetchTransactionRequest());
  }, [dispatch]);

  useEffect(() => {
    if (amountType !== "other") {
      localStorage.setItem("amount", amountType);
      setAmount(amountType);
      setChipz(Math.round(amountType * (8521 / 5)));
    } else {
      localStorage.setItem("amount", "");
      setAmount("");
      setChipz(0);
    }
  }, [amountType]);

  const handleTabSelect = (selectedTab) => {
    if (selectedTab === "buy") {
      setTransactionMode(selectedTab);
      setRefundType("");
    } else if (selectedTab === "refund") {
      setTransactionMode(selectedTab);
      setAmountType("");
      localStorage.removeItem("amount");
      setChipz(0);
    }
  };

  const handleAmountTypeSelect = (selectedAmountType) => {
    if (selectedAmountType === "other") {
      setAmountType(selectedAmountType);
      setAmount("");
      localStorage.setItem("amount", "");
      setChipz(0);
    } else {
      setAmountType(selectedAmountType);
      setAmount(selectedAmountType);
      localStorage.setItem("amount", selectedAmountType);
      setChipz(selectedAmountType !== "" ? Math.round(selectedAmountType * (8521 / 4.5)) : "");
    }
  };

  const handleAmountChange = (event) => {
    const val = event.target.value
    setAmount(val.toString());
    localStorage.setItem("amount", val.toString());
    setChipz(Math.round((event.target.value * (1704.2))));
  };

  const setVisible = (active) => {
    if (activeSection === active) setActiveSection("");
    else setActiveSection(active);
  };

  const createOrder = async () => {
    const chipzAmount = localStorage.getItem("amount");
    if (chipzAmount === "" || chipzAmount === null || chipzAmount === undefined) return toast.error("Please select amount");

    return new Promise((resolve, reject) => {
      dispatch(
        checkoutByPayPal({
          requestData: { total: chipzAmount },
          successCallBack: (response) => {
            setIsLoading(false);
            localStorage.removeItem("amount");
            setAmountType("");
            setAmount(0);
            resolve(response.id);
          },
          failCallBack: async () => {
            setIsLoading(false);
            localStorage.removeItem("amount");
          }
        })
      );
    });
  };

  const onApprove = async (data, actions) => {
    setIsLoading(true);
    dispatch(
      purchaseChipsByPayPal({
        requestData: { order_id: data.orderID, payer_id: data.payerID },
        successCallBack: (response) => {
          setIsLoading(false);
          setAmountType("");
          setAmount(0);
          setTimeout(() => {
            navigate('/ChipzBalance');
          }, 1000);
        },
        failCallBack: () => setIsLoading(false),
      })
    );
  };

  const onApproveReFund = async () => {
    if (refundType === "" || refundType === null || refundType === undefined || email === "" || email === null || email === undefined) return toast.error("Please select rundType & provide PayPal email");

    setIsLoading(true);
    dispatch(
      refundChipsByPayPal({
        requestData: { email, refundType },
        successCallBack: (response) => {
          setIsLoading(false);
          dispatch(fetchChipsBalanceRequest());
          setEmail("");
          setTimeout(() => {
            navigate(`/ChipzBalance`);
          }, 3000);
        },
        failCallBack: () => setIsLoading(false),
      })
    );
  };

  let chipsBalanceContent;
  let paymentsContent;
  if (chipsBalanceLoading || paymentsLoading) {
    chipsBalanceContent = <p>Loading chips balance...</p>;
    paymentsContent = <p>Loading Emails...</p>;
  } else if (chipsBalanceError || paymentsError) {
    chipsBalanceContent = (
      <p>Error loading chips balance: {chipsBalanceError}</p>
    );
    paymentsContent = (
      <p>Error loading emails: {paymentsError}</p>
    );
  } else {
    paymentsContent = payments
    chipsBalanceContent = (
      <h1>
        {" "}
        {chipsBalance} <span>Chipz</span>{" "}
      </h1>
    );
  }

  return (
    <div
      className={
        isActive
          ? "challenge-details-page singup-page transaction-history-page active"
          : "challenge-details-page singup-page transaction-history-page"
      }
    >
      <Sidebar activeSection={activeSection} />
      <VisibilitySensor onChange={() => setVisible("ChipzBalance")}>
        <div className="content">
          <NavbarInner />
          <div className="transaction-box signup-form">
            <div className="challenge-top-area">
              <h2>Chipz Balance</h2>
            </div>
            <div className="balnce-box">
              <h1>{chipsBalanceContent}</h1>
            </div>
            <Tabs
              defaultActiveKey="buy"
              id="uncontrolled-tab-example"
              className="mb-3"
              onSelect={handleTabSelect}
            >
              <Tab eventKey="buy" title="Buy">
                <div className="amount-box style-two">
                  <h4>Choose Amount</h4>
                  <ul className="list-x">
                    <li>
                      <button
                        className={amountType === "5" && "active"}
                        onClick={() => handleAmountTypeSelect("5")}
                      >
                        $5
                      </button>
                    </li>
                    <li>
                      <button
                        className={amountType === "10" && "active"}
                        onClick={() => handleAmountTypeSelect("10")}
                      >
                        $10
                      </button>
                    </li>
                    <li>
                      <button
                        className={amountType === "20" && "active"}
                        onClick={() => handleAmountTypeSelect("20")}
                      >
                        $20
                      </button>
                    </li>
                    <li>
                      <button
                        className={amountType === "50" && "active"}
                        onClick={() => handleAmountTypeSelect("50")}
                      >
                        $50
                      </button>
                    </li>
                    <li>
                      <button
                        className={amountType === "100" && "active"}
                        onClick={() => handleAmountTypeSelect("100")}
                      >
                        $100
                      </button>
                    </li>
                    <li>
                      <button
                        className={amountType === "250" && "active"}
                        onClick={() => handleAmountTypeSelect("250")}
                      >
                        $250
                      </button>
                    </li>
                    <li>
                      <button
                        className={
                          amountType === "other" && "active"
                        }
                        onClick={() => handleAmountTypeSelect("other")}
                      >
                        Other
                      </button>
                    </li>
                  </ul>
                  <ValidatorForm className="validatorForm">
                    <Grid
                      container
                      spacing={1}
                      className="group-input"
                      alignItems="flex-end"
                    >
                      <Grid className="signup-fields" item xs={12}>
                        <CustomTextField
                          fullWidth
                          className="MyTextField"
                          placeholder="Minimum 5 Chips"
                          autoComplete="off"
                          disabled={amountType !== "other"}
                          type="number"
                          value={amount}
                          margin="dense"
                          onChange={(event) => handleAmountChange(event)}
                          errorMessages={["Maximum can not be empty"]}
                        />
                        <span>USD $</span>
                      </Grid>
                    </Grid>
                    <p>
                      ~ {chipz !== "" ? chipz : "0"}{" "}
                      Bodega Chipz`
                    </p>
                    {!isLoading ? (
                      <PayPalScriptProvider
                        options={{
                          "client-id": "AcZZ8I640RqZ7n6cCwdgDVnUqqpWhYiRiCL_Yr3KU87NLPMueeDXSy2GzHQlZ_4JlMvtoyxyOXv5dUkS",
                          "disable-funding": ["venmo", "card", "paylater"],
                        }}
                      >
                        <PayPalButtons
                          style={{
                            shape: "pill",
                            label: "checkout",
                            layout: "vertical",
                          }}
                          className="paypal-button"
                          createOrder={createOrder}
                          onApprove={(data, action) => onApprove(data, action)}
                        />
                      </PayPalScriptProvider>
                    ) : (
                      <div
                        style={{
                          width: "100%",
                          height: "3rem",
                          display: "flex",
                          justifyContent: "center",
                          alignItems: "center",
                          paddingBlock: "0.5rem",
                          background: "transparent",
                          border: "2px solid #fbe02e",
                          borderRadius: "24px",
                          borderColor: "rgb(163, 142, 3)",
                          marginBottom: "0.5rem",
                        }}
                      >
                        <i className="fa fa-spinner fa-spin fa-1x fa-fw" />
                      </div>
                    )}
                  </ValidatorForm>
                </div>
              </Tab>
              <Tab eventKey="refund" title="Refund">
                <div className="amount-box style-two">
                  <div className="btns-group">
                    <button
                      className={`refund-btn ${refundType === "partial" ? "active" : ""
                        }`}
                      onClick={() => setRefundType("partial")}
                    >
                      <i>Refund 50%</i>
                      <span> {chipsBalance / 2} Chipz</span>
                    </button>
                    <button
                      className={`refund-btn ${refundType === "full" ? "active" : ""
                        }`}
                      onClick={() => setRefundType("full")}
                    >
                      <i>Refund 100%</i>
                      <span> {chipsBalance} Chipz</span>
                    </button>
                  </div>
                  <div
                    style={{
                      display: "flex",
                      flexDirection: "column",
                      gap: "0.75rem",
                    }}
                  >
                    <ValidatorForm className="validatorForm">
                      <InputLabel htmlFor="email" style={{color: "rgb(185, 180, 170)", fontSize: "20px", marginBlock: "1.25rem"}}>Select your PayPal email</InputLabel>
                      <RadioGroup id="email" style={{marginInline: "0.75rem"}} aria-labelledby="email" name="email" value={email} onChange={(e) => setEmail(e.target.value)}>
                        {
                          paymentsContent.length > 0 && paymentsContent.map((item, index) => {
                            return <FormControlLabel key={index} value={item} control={<Radio style={{
                              color: 'blue'
                            }}/>} label={item} />
                          })
                        }
                      </RadioGroup>
                      <Button
                        type="submit"
                        style={{
                          background: `${isLoading ? "transparent" : "rgb(251,224,46)"
                            }`,
                          marginTop: "0.75rem"
                        }}
                        disabled={isLoading || !email}
                        onClick={() => {
                          onApproveReFund();
                        }}
                      >
                        {!isLoading ? (
                          "REFUND CHIPZ"
                        ) : (
                          <i className="fa fa-spinner fa-spin fa-1x fa-fw" />
                        )}
                      </Button>
                    </ValidatorForm>
                  </div>
                </div>
              </Tab>
            </Tabs>
          </div>
        </div>
      </VisibilitySensor>
    </div>
  );
};

export default ChipzBalance;