import React, { useState } from "react";
import { useDispatch } from "react-redux";
import { Link } from "react-router-dom";
import { HashLink } from "react-router-hash-link";
import { signOut } from "../../store/actions/Auth";

import "./index.css";

const Navbar = ({ sticky, auth, activeSection }) => {
  const [setIsOpen] = useState(false)
  const dispatch = useDispatch();

  return (
    <div className={`chips-exchange-nav ${sticky && "sticky-nav"}`}>
      <nav
        className="navbar navbar-expand-lg sidenav"
        id="sidenav-1"
        data-mdb-hidden="false"
      >
        <div className=" inner-container">
          <Link className="navbar-brand" to="/">
            <img src={require("../../static/images/logo.png")} alt="" />
          </Link>
          <button
            onClick={() => setIsOpen(true)}
            className="navbar-toggler"
            type="button"
            data-toggle="collapse"
            data-target="#navbarSupportedContent"
            aria-controls="navbarSupportedContent"
            aria-expanded="false"
            aria-label="Toggle navigation"
            aria-haspopup="true"
          >
            <i class="icon" aria-hidden="true">
              <img
                src={require("../../static/images/mobile-menu.png")}
                alt=""
              />
            </i>
          </button>
          <div
            className="collapse navbar-collapse nav-links"
            id="navbarSupportedContent"
          >
            <div className="mobile-menu-logo">
              <Link className="navbar-brand" to="/">
                <img src={require("../../static/images/logo.png")} alt="" />
              </Link>
              <button
                onClick={() => setIsOpen(false)}
                className="navbar-toggler"
                type="button"
                data-toggle="collapse"
                data-target="#navbarSupportedContent"
                aria-controls="navbarSupportedContent"
                aria-expanded="false"
                aria-label="Toggle navigation"
                aria-haspopup="true"
              >
                <i class="icon" aria-hidden="true">
                  <img
                    src={require("../../static/images/menu-close.png")}
                    alt=""
                  />
                </i>
              </button>
            </div>
            <ul className="navbar-nav sidenav-menu">
              <li className="nav-item">
                <HashLink
                  smooth
                  to="#home"
                  className={`nav-link ${
                    activeSection === "Banner" && "active"
                  }`}
                >
                  Home
                </HashLink>
              </li>
              <li className="nav-item">
                <HashLink
                  smooth
                  to="#services"
                  className={`nav-link ${
                    activeSection === "Services" && "active"
                  }`}
                >
                  Services
                </HashLink>
              </li>
              <li className="nav-item">
                <HashLink
                  smooth
                  to="#faqs"
                  className={`nav-link ${activeSection === "FAQs" && "active"}`}
                >
                  FAQs
                </HashLink>
              </li>
            </ul>
            <div className="searchbox">
              {!auth ? (
                <>
                  <Link className="btn-style-two" to="/SignUp">
                    Sign up
                  </Link>
                  <Link className="btn-style-one" to="/SignIn">
                    Sign in
                  </Link>
                </>
              ) : (
                <>
                  <Link className="btn-style-two" to="/ChipzBalance">
                    Exchange
                  </Link>
                  <Link
                    className="btn-style-one"
                    to="/"
                    onClick={() => {
                      dispatch(signOut());
                      window.location.reload();
                    }}
                  >
                    Logout
                  </Link>
                </>
              )}
            </div>
          </div>
        </div>
      </nav>
    </div>
  );
};

export default Navbar;
