import React, { useEffect, Suspense, lazy } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { BrowserRouter as Router, Navigate, Routes, Route } from "react-router-dom";
import EventBus from "eventing-bus";
import { toast, ToastContainer } from 'react-toastify';
import Error from '@material-ui/icons/Error';
import CheckCircle from '@material-ui/icons/CheckCircle';
import Preloader from '../components/preloader';
import ChipsExchange from './ChipsExchange';
import SignUp from './SignUp';
import SignIn from './SignIn';
import ForgotPassword from './ForgotPassword';
import EnterPasscode from './EnterPasscode';
import Welcome from './Welcome';
import ChipzBalance from './ChipzBalance';
import TransactionHistory from './TransactionHistory';
import ChallengeDetails from './ChallengeDetails';
import NewPassword from "./NewPassword";

import { signOut } from '../store/actions/Auth';

import '../static/css/style.css';
import 'jquery/dist/jquery.min.js';
import 'bootstrap/dist/js/bootstrap.min.js';
import 'bootstrap/dist/css/bootstrap.min.css';
import 'font-awesome/css/font-awesome.min.css';
import "react-toastify/dist/ReactToastify.css";

const BounceLoader = lazy(() => import("../components/bounceloader"));

const App = () => {
  const dispatch = useDispatch();
  const auth = useSelector((state) => state.Auth.auth);

  useEffect(() => {
    EventBus.on('tokenExpired', () => dispatch(signOut()));
    EventBus.on('info', (e) => toast.info(<div> <Error /> {e}</div>));
    EventBus.on('error', (e) => toast.error(<div> <Error /> {e}</div>));
    EventBus.on('success', (e) => toast.success(<div> <CheckCircle /> {e}</div>));
  }, [dispatch]);

  return (
    <div>
      <Suspense fallback={<Preloader />}>
        <BounceLoader />
        <ToastContainer closeOnClick position="bottom-left" />
        <Router>
          <Routes>
            <Route exact path='/' element={<ChipsExchange />} />
            <Route exact path='/SignUp' element={<SignUp />} />
            <Route exact path='/SignIn' element={<SignIn />} />
            <Route exact path='/ForgotPassword' element={<ForgotPassword />} />
            <Route exact path='/enterPasscode' element={<EnterPasscode />} />
            <Route exact path='/newpassword' element={<NewPassword />} />
            <Route exact path='/Welcome' element={<Welcome />} />
            <Route exact path='/ChipzBalance' element={auth ? <ChipzBalance /> : <Navigate to="/" />} />
            <Route exact path='/TransactionHistory' element={auth ? <TransactionHistory /> : <Navigate to="/" />} />
            <Route exact path='/ChallengeDetails' element={auth ? <ChallengeDetails /> : <Navigate to="/" />} />
          </Routes>
        </Router>
      </Suspense>
    </div>
  );
};

export default App;