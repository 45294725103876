import WOW from "wowjs";
import React, { useState, useEffect } from "react";
import {
  fetchTransactionHistoryRequest,
  fetchChipsBalanceRequest,
  getUserName,
} from "../../store/actions/Auth";
import VisibilitySensor from "react-visibility-sensor";

import "./index.css";
import "../../static/css/animate.css";
import "animate.css";

import Sidebar from "../../components/sidebar";
import NavbarInner from "../../components/navbar-inner";
import { useDispatch, useSelector } from "react-redux";

const TransactionHistory = () => {
  const [activeSection, setActiveSection] = useState("");

  const dispatch = useDispatch();
  const {
    transactions,
    transactionsLoading,
    transactionsError,
    chipsBalance,
    chipsBalanceLoading,
    chipsBalanceError,
    username,
    isActive,
  } = useSelector((state) => ({
    transactions: state.Auth.transactions,
    transactionsLoading: state.Auth.transactionsLoading,
    transactionsError: state.Auth.transactionsError,
    chipsBalance: state.Auth.chipsBalance,
    chipsBalanceLoading: state.Auth.chipsBalanceLoading,
    chipsBalanceError: state.Auth.chipsBalanceError,
    username: state.Auth.username,
    isActive: state.Sidebar.isActive,
  }));

  useEffect(() => {
    new WOW.WOW({ live: true }).init();
    dispatch(fetchTransactionHistoryRequest());
    dispatch(fetchChipsBalanceRequest());
    dispatch(getUserName());
  }, [dispatch]);

  const setVisible = (active) => {
    setActiveSection((prevActiveSection) =>
      prevActiveSection === active ? "" : active
    );
  };

  const filteredTransactions = transactions.filter(
    (transaction) => transaction.gameStatus !== null
  );

  const lastTransaction =
    filteredTransactions.length > 0 ? filteredTransactions[0] : null;

  const lastTransactionResult = lastTransaction
    ? lastTransaction.gameStatus
    : null;

  let calculatedChipsBalance;

  if (lastTransactionResult === "winner") {
    calculatedChipsBalance = `${chipsBalance - lastTransaction._doc.challenged_amount
      } + ${lastTransaction._doc.challenged_amount}`;
  } else if (lastTransactionResult === "loser") {
    calculatedChipsBalance = `${chipsBalance + lastTransaction._doc.challenged_amount
      } - ${lastTransaction._doc.challenged_amount}`;
  } else {
    calculatedChipsBalance = chipsBalance;
  }

  let chipsBalanceContent;

  if (chipsBalanceLoading) {
    chipsBalanceContent = <p>Loading chips balance...</p>;
  } else if (chipsBalanceError) {
    chipsBalanceContent = (
      <p>Error loading chips balance: {chipsBalanceError}</p>
    );
  } else {
    chipsBalanceContent = (
      <h1>
        {" "}
        {chipsBalance} <span>Chipz</span>{" "}
      </h1>
    );
  }

  return (
    <div
      className={
        isActive
          ? "transaction-history-page singup-page active"
          : "transaction-history-page singup-page"
      }
    >
      <Sidebar activeSection={activeSection} />
      <VisibilitySensor onChange={() => setVisible("TransactionHistory")}>
        <div className="content">
          <NavbarInner />
          <div className="transaction-box">
            <div className="transaction-top-area">
              <h2>Transaction history</h2>
              <p className="username">{username}</p>
            </div>
            <div className="chiz-area">
              <p>{calculatedChipsBalance}</p>
              <h1>
                {chipsBalanceContent}
              </h1>
            </div>
            {transactionsLoading ? (
              <p>Loading challenges...</p>
            ) : filteredTransactions.length === 0 ? (
              <p>No transactions found</p>
            ) : transactionsError ? (
              <p>Error loading challenges: {transactionsError}</p>
            ) : (
              filteredTransactions.map((transaction) => (
                <div key={transaction._doc._id} className="chips-box">
                  <div className="left-area">
                    <h4>
                      {transaction._doc.gameName}{" "}
                      <span>
                        {transaction.gameStatus === "winner"
                          ? "(won)"
                          : "(lost)"}
                      </span>
                    </h4>
                    <p>{transaction._doc.createdAt}</p>
                  </div>
                  <div className="right-area">
                    <h4
                      className={
                        transaction.gameStatus === "winner"
                          ? "plus-trans"
                          : "minus-trans"
                      }
                    >
                      <i className="icon">
                        <img
                          src={require("../../static/images/loader.png")}
                          alt=""
                        />
                      </i>{" "}
                      {transaction._doc.challenged_amount}
                    </h4>
                  </div>
                </div>
              ))
            )}
          </div>
        </div>
      </VisibilitySensor>
    </div>
  );
};

export default TransactionHistory;
