import WOW from "wowjs";
import { Button } from "reactstrap";
import React, { useEffect } from "react";

import "./index.css";
import "../../static/css/animate.css";
import "animate.css";

import Sidebar from "../../components/sidebar";
import { useSelector } from "react-redux";

const Welcome = () => {
  const loading = useSelector((state) => state.Auth.loading);
  const isActive = useSelector((state) => state.Sidebar.isActive);

  useEffect(() => {
    new WOW.WOW({
      live: true,
    }).init();
  }, []);

  return (
    <div className={isActive ? "singup-page active" : "singup-page"}>
      <Sidebar />
      <div className="content">
        <div className="signup-form">
          <h2>All set welcome !</h2>
          <p>Try to signIn for next step.</p>
          <div className="img-area">
            <img src={require("../../static/images/welcome-img.png")} alt="" />
          </div>
          <h2 className="center-heding">
            Your password has been <br /> successfully changed!
          </h2>
          <Button type="submit" disabled={loading}>
            {!loading ? (
              "Get Started"
            ) : (
              <i className="fa fa-spinner fa-spin fa-1x fa-fw" />
            )}
          </Button>
        </div>
      </div>
    </div>
  );
};

export default Welcome;
