import WOW from "wowjs";
import Grid from "@material-ui/core/Grid";
import { Button } from "reactstrap";
import { withStyles } from "@material-ui/core/styles";
import { ValidatorForm, TextValidator } from "react-material-ui-form-validator";
import React, { useState, useEffect } from "react";
import { Link, useNavigate, useLocation } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";

import "./index.css";
import "../../static/css/animate.css";
import "animate.css";

import Sidebar from "../../components/sidebar";
import { toggleLoader, enterPasscodeRequest } from "../../store/actions/Auth";

const CustomTextField = withStyles({
  root: {
    "& .MuiInputBase-input": {
      color: "#fff", // Text color
    },
    "& .MuiInput-underline:before": {
      borderBottomColor: "#fff", // Semi-transparent underline
    },
    "& .MuiInput-underline:hover:before": {
      borderBottomColor: "#fff", // Solid underline on hover
    },
    "& .MuiInput-underline:after": {
      borderBottomColor: "#fa6634", // Solid underline on focus
    },
  },
  input: {
    "&:-webkit-autofill": {
      transitionDelay: "9999s",
      transitionProperty: "background-color, color",
    },
  },
})(TextValidator);

const EnterPasscode = () => {
  const [digit1, setDigit1] = useState("");
  const [digit2, setDigit2] = useState("");
  const [digit3, setDigit3] = useState("");
  const [digit4, setDigit4] = useState("");

  const loading = useSelector((state) => state.Auth.loading);
  const isActive = useSelector((state) => state.Sidebar.isActive);

  const dispatch = useDispatch();
  const navigate = useNavigate();
  const location = useLocation();

  const email = location.state?.email;

  useEffect(() => {
    new WOW.WOW({ live: true }).init();
  }, []);

  const handleFormChange = (event) => {
    const { name, value } = event.target;
    switch (name) {
      case "digit1":
        setDigit1(value);
        break;
      case "digit2":
        setDigit2(value);
        break;
      case "digit3":
        setDigit3(value);
        break;
      case "digit4":
        setDigit4(value);
        break;
      default:
        break;
    }
  };

  const handleFormSubmit = async () => {
    const passcode = `${digit1}${digit2}${digit3}${digit4}`;
    dispatch(toggleLoader(true));
    await dispatch(
      enterPasscodeRequest({ data: { passcode: parseInt(passcode), email }, history: navigate })
    );
    dispatch(toggleLoader(false));
  };

  const handleInput = (event, nextField) => {
    const value = event.target.value;
    if (value && nextField) {
      document.getElementsByName(nextField)[0].focus();
    }
  };

  return (
    <div className={isActive ? "singup-page active" : "singup-page"}>
      <Sidebar />
      <div className="content">
        <div className="signup-form">
          <h2>Enter your passcode</h2>
          <p>We’ve sent the code to your registered email. </p>
          <div className="img-area">
            <img src={require("../../static/images/passcode-img.png")} alt="" />
          </div>

          <ValidatorForm className="validatorForm" onSubmit={handleFormSubmit}>
            <Grid
              container
              spacing={1}
              className="group-input"
              alignItems="flex-end"
            >
              <Grid className="signup-fields passcode-fields" item xs={12}>
                <CustomTextField
                  fullWidth
                  className="MyTextField"
                  placeholder=""
                  autoComplete="off"
                  name="digit1"
                  type="number"
                  value={digit1}
                  margin="dense"
                  onChange={handleFormChange}
                  onInput={(e) => handleInput(e, "digit2")}
                  validators={["required"]}
                  errorMessages={["Pass code can not be empty"]}
                  maxLength="1"
                />
                <CustomTextField
                  fullWidth
                  className="MyTextField"
                  placeholder=""
                  autoComplete="off"
                  name="digit2"
                  type="number"
                  value={digit2}
                  margin="dense"
                  onChange={handleFormChange}
                  onInput={(e) => handleInput(e, "digit3")}
                  validators={["required"]}
                  errorMessages={["Pass code can not be empty"]}
                  maxLength="1"
                />
                <CustomTextField
                  fullWidth
                  className="MyTextField"
                  placeholder=""
                  autoComplete="off"
                  name="digit3"
                  type="number"
                  value={digit3}
                  margin="dense"
                  onChange={handleFormChange}
                  onInput={(e) => handleInput(e, "digit4")}
                  validators={["required"]}
                  errorMessages={["Pass code can not be empty"]}
                  maxLength="1"
                />
                <CustomTextField
                  fullWidth
                  className="MyTextField"
                  placeholder=""
                  autoComplete="off"
                  name="digit4"
                  type="text"
                  value={digit4}
                  margin="dense"
                  onChange={handleFormChange}
                  validators={["required"]}
                  errorMessages={["Pass code can not be empty"]}
                  maxLength="1"
                />
              </Grid>
            </Grid>
            <p className="recive-code-text">
              Didn’t receive code?{" "}
              <Link className="recive-code" to="/forgotPassword">
                {" "}
                Resend Code
              </Link>
            </p>
            <Button type="submit" disabled={loading}>
              {!loading ? (
                "Verify Password"
              ) : (
                <i className="fa fa-spinner fa-spin fa-1x fa-fw" />
              )}
            </Button>
          </ValidatorForm>
        </div>
      </div>
    </div>
  );
};

export default EnterPasscode;
